
import Page from '@/components/page/Page'
import { mapGetters } from 'vuex'
import SharedContentType from '@/components/page/SharedContentType'
import storyblokLivePreview from '@/mixins/storyblokLivePreview'
import { getUrlParams } from '@/modules/queryParser'
import SalesApi from '@/modules/SalesApi'
export default {
  components: { Page, SharedContentType },
  mixins: [storyblokLivePreview],
  async fetch({ store, params, $config, error }) {
    if (
      params.productLine !== store.getters.route.productLine ||
      params.funnel !== store.getters.route.funnel
    ) {
      await store.dispatch('split-testing/clearTest')
    }

    await store
      .dispatch('getStory', {
        slug: `${params.productLine}/${params.funnel}/${params.step}`,
        salesApi: new SalesApi($config)
      })
      .catch(err => {
        error({
          error: err.message,
          statusCode: 404
        })
      })
  },
  data() {
    return { snackbar: false }
  },
  computed: {
    ...mapGetters({
      queryParameters: 'queryParameters'
    })
  },
  created() {
    this.$store.dispatch('timer/initializeTimerState')
  },
  mounted() {
    window.addEventListener('visibilitychange', this.handleSessionStorage, {
      once: true
    })
    const params = getUrlParams(window.location.search)
    this.$store.dispatch('updateQueryParams', this.$route.query).then(() => {
      const promises = [
        this.$store.dispatch('enterPage', this.$route.params),
        this.$store.dispatch('pageEnter'),
        this.$store.dispatch('analytics/setVendor', params)
      ]
      if (this.$route.query.splittest) {
        promises.push(
          this.$store.dispatch(
            'split-testing/setSplitTestPreview',
            this.$route.query
          )
        )
        const { productLine, funnel, step } = this.$route.params
        promises.push(
          this.$store.dispatch('getStep', {
            slug: `${productLine}/${funnel}/${step}`,
            salesApi: new SalesApi(this.$config)
          })
        )
      }

      if (!this.$store.getters['split-testing/inSplitTest']) {
        promises.push(
          this.$store.dispatch('split-testing/getActiveSplitTest').then(() => {
            const { productLine, funnel, step } = this.$route.params
            this.$store.dispatch('initSplitTest', {
              slug: `${productLine}/${funnel}/${step}`,
              salesApi: new SalesApi(this.$config)
            })
          })
        )
      }

      return Promise.all(promises).then(() => {
        this.$store.dispatch('analytics/sendEvent', 'PageEnter')
        this.$store.dispatch('sendGTMEvent', {
          event: 'Enter Page',
          payload: {
            productIds: this.$store.getters.offers.map(p => p.product.id)
          }
        })
        this.sendBlotoutViews()
      })
    })
    setTimeout(() => {
      this.checkUtm()
    }, 1000)
  },
  beforeDestroy() {
    window.removeEventListener('visibilitychange', this.handleSessionStorage)
  },
  destroyed() {
    this.$store.dispatch(
      'timer/clearSessionStorageCounter',
      this.$router.history._startLocation.slice(1)
    )
  },
  methods: {
    sendBlotoutViews() {
      this.$blotout.sendEvent('PageView')
      const eventTypes = {
        lander: 'ViewContent',
        checkout: 'AddToCart'
      }
      const type = this.$store.getters.stepType
      if (Object.keys(eventTypes).includes(type)) {
        const category = this.$store.getters.route.productLine
        const url = this.$store.getters['analytics/url'].base
        const options = {
          currency: 'USD',
          contents: this.$store.getters.offers.map(offer => {
            return {
              id: offer.product.id,
              quantity: 1,
              item_price: offer.pricing.displayPrice,
              title: offer.name,
              category,
              url
            }
          })
        }
        this.$blotout.sendEvent(eventTypes[type], options)
      }
    },
    checkUtm() {
      if (this.$store.getters.queryString) {
        for (
          let i = 0;
          i < document.getElementsByClassName('utm-link').length;
          i++
        ) {
          if (document.getElementsByClassName('utm-link')[i].children[0].href) {
            document.getElementsByClassName('utm-link')[i].children[0].href =
              document.getElementsByClassName('utm-link')[i].children[0].href +
              this.$store.getters.queryString
          }
        }
      }
    },
    handleSessionStorage() {
      if (
        this.$store.state.timer.stack[this.$store.state.slug].filter(
          t => t.isDateTime === false
        ).length > 0
      ) {
        this.$store.dispatch('timer/setSessionStorageCounter')
      } else {
        this.$store.dispatch('timer/clearSessionStorageCounter')
      }
    }
  },
  beforeRouteUpdate(to, from, next) {
    if (to.hash === '#checkoutForm') {
      // setTimeout needed to focus element due to lifecycle timing
      setTimeout(() => {
        document.getElementById('firstName').focus()
      }, 0)
    } else {
      if (!to.hash) {
        this.$store.dispatch('pageLeave').then(() => {
          this.$store.dispatch('analytics/sendEvent', 'PageLeave')
        })
      }
      next()
    }
  }
}
