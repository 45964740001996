export const getUrlParams = search => {
  const hashes = search.slice(search.indexOf('?') + 1).split('&')
  return hashes.reduce((params, hash) => {
    const [key, val] = hash.split('=')
    if ([key, val][0] === '') {
      return Object.assign(params, {
        name: '',
        campaignId: null,
        contactId: null,
        contactEmail: ''
      })
    }
    return Object.assign(params, { [key]: decodeURIComponent(val) })
  }, {})
}
